/* ##############################################

Código loader Spinner fuera de la aplicación

############################################## */

.outside-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
    height: 50px;
}

$segmentWidth: 6px;

.spinner {
    width: 50px;
    height: 50px;
    border-top: $segmentWidth solid rgba(169, 169, 169, 0.2);
    border-right: $segmentWidth solid rgba(169, 169, 169, 0.2);
    border-bottom: $segmentWidth solid rgba(169, 169, 169, 0.2);
    border-left: $segmentWidth solid #353535;
    border-radius: 50%;
    -webkit-animation: loadOutside 1.1s infinite linear;
    animation: loadOutside 1.1s infinite linear;
}

@-webkit-keyframes loadOutside {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loadOutside {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
